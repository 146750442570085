<template>
	<section class="bg-mono-black py-7">
		<div class="container flow-medium">
			<h1 class="family-heading font-largest">Thanks 💙</h1>
			<p>Thank you! We'll see in you March!</p>

			<div>
				<router-link class="color-brand-accent weight-bold" :to="{ path: '/' }">&larr; Go back</router-link>
			</div>
		</div>
	</section>
</template>